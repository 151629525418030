<template>
<div>
    <columns>
        <column>
            <div class="box is-fullheight is-round p-5">
                <icon icon="wave-square" class="fa-2x has-text-grey"/>
                <p>
                Last Active
                </p>
                <div class="is-flex is-align-items-center is-justify-content-space-between">
                <p class="is-size-5">{{ workOrder.updated_at | asCalendar }}</p>
                </div>
            </div>
        </column>
        <column>
            <div class="box is-fullheight is-round p-5">
                <icon icon="calendar" type="far" class="fa-2x has-text-grey"/>
                <p>
                    Schedule
                </p>
                <div class="is-flex is-align-items-center is-justify-content-space-between">
                <work-order-schedule 
                    :is-overdue="isOverdue"
                    :status="workOrder.status"
                />
                </div>
            </div>
        </column>
        <column>
            <div class="box is-fullheight is-round p-5">
                <icon icon="people-arrows" class="fa-2x has-text-grey"/>
                <p>
                    Team
                </p>
                <div class="is-flex is-align-items-center is-justify-content-space-between">
                <p class="is-size-5">
                    <router-link v-if="!$root.isPassiveUser()" :to="{
                        name: 'team-manager',
                        params: {
                            team: workOrder.team.uuid
                        }
                    }">{{ workOrder.team.name }}</router-link>
                    <span v-else>{{ workOrder.team.name }}</span>
                </p>
                </div>
            </div>
        </column>
        <column>
            <div class="box is-fullheight is-round p-5">
                <icon icon="stopwatch" class="fa-2x has-text-grey"/>
                <p>
                Man hours spent
                </p>
                <div class="is-flex is-align-items-center is-justify-content-space-between">
                <p class="is-size-5">{{ totalHours }} hours</p>
                </div>
            </div>
        </column>
        <column>
            <div class="box is-fullheight is-round p-5">
                <icon icon="bolt" class="fa-2x has-text-grey"/>
                <p>
                Status
                </p>
                <div class="is-flex is-align-items-center is-justify-content-space-between">
                <p class="is-size-5">{{ workOrder.status | ucwords }}</p>
                </div>
            </div>
        </column>
    </columns>

    <columns>
        <div class="column is-4">
            <p class="has-text-info has-text-weight-bold">Overview</p>
            <div class="box is-fullheight">
                <spaced-text>
                    <span>Priority</span>
                    <span class="has-text-weight-bold" :class="{
                        'has-text-success': workOrder.priority.level === 'low',
                        'has-text-warning': workOrder.priority.level === 'medium',
                        'has-text-danger': workOrder.priority.level === 'high',
                    }">{{ workOrder.priority.level | ucwords }}</span>
                </spaced-text>
                <spaced-text>
                    <span>Customer</span>
                    <span class="has-text-weight-bold">{{ workOrder.customer.name }}</span>
                </spaced-text>
                <spaced-text>
                    <span>Location</span>
                    <span class="has-text-weight-bold">{{ workOrder.location.name }}</span>
                </spaced-text>
                <spaced-text>
                    <span>Opened By</span>
                    <span class="has-text-weight-bold">{{ workOrder.creator.full_name }}</span>
                </spaced-text>
                <spaced-text>
                    <span>Created</span>
                    <span class="has-text-weight-bold">{{ workOrder.created_at | date('Y-MM-DD HH:MM:ss') }}</span>
                </spaced-text>
                <spaced-text>
                    <span>Due Date</span>
                    <span v-if="workOrder.ending_at" class="has-text-weight-bold">{{ workOrder.ending_at | date('Y-MM-DD HH:MM:ss') }}</span>
                    <span v-else class="has-text-weight-bold">--</span>
                </spaced-text>
                <spaced-text>
                    <span>Team</span>
                    <span class="has-text-weight-bold">{{ workOrder.team.name }}</span>
                </spaced-text>
                <spaced-text>
                    <span>Team Lead</span>
                    <span class="has-text-weight-bold">{{ workOrder.team.leader.full_name }}</span>
                </spaced-text>
                <spaced-text>
                    <span>Checklists</span>
                    <span class="has-text-weight-bold">{{ workOrder.inspections.length }}</span>
                </spaced-text>
            </div>
        </div>
        <column>
            <p class="has-text-info has-text-weight-bold">Brief</p>
            <div v-if="workOrder.brief.length" class="box is-fullheight" v-html="cleanedBrief"></div>
            <div v-else class="box is-fullheight">
                <p>A brief has not been provided for this work order.</p>
            </div>
        </column>
    </columns>

    <loader v-bind="{ loading }">
        <inspection-list class="mt-6" />
    </loader>
</div>  
</template>
<script>
import InspectionList from './partials/InspectionList.vue'
import { mapGetters } from 'vuex'
import moment from 'moment'
import WorkOrderSchedule from '../partials/WorkOrderSchedule.vue'
import { workOrder as backend } from '@/api'

export default {

    components: {
        InspectionList,
        WorkOrderSchedule
    },

    data: () => ({
        loading: true
    }),

    beforeCreate() {
        backend.loadInspections(this.$route.params.workOrder, ({data}) => {
            this.$store.commit('workOrder/setInspections', data)
            this.loading = false
        }, () => {
            this.loading = false
        })
    },

    computed: {
        ...mapGetters('workOrder', [
            'workOrder'
        ]),
        totalHours() {
            const totalTime = this.workOrder.inspections.reduce((total, inspection) => total + inspection.total_run_time, 0)
            if(totalTime === 0)  return 0
            return ((totalTime / 60) / 60).toFixed()
        },
        isOverdue() {
            if(!this.workOrder.ending_at || this.workOrder.closed_off_at) return false

            return moment(this.workOrder.ending_at).isBefore(moment()) 
        },
        cleanedBrief() {
            return this.workOrder.brief.replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, `$1<br>$2`)
        }
    }

}
</script>