var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('columns',[_c('column',[_c('div',{staticClass:"box is-fullheight is-round p-5"},[_c('icon',{staticClass:"fa-2x has-text-grey",attrs:{"icon":"wave-square"}}),_c('p',[_vm._v(" Last Active ")]),_c('div',{staticClass:"is-flex is-align-items-center is-justify-content-space-between"},[_c('p',{staticClass:"is-size-5"},[_vm._v(_vm._s(_vm._f("asCalendar")(_vm.workOrder.updated_at)))])])],1)]),_c('column',[_c('div',{staticClass:"box is-fullheight is-round p-5"},[_c('icon',{staticClass:"fa-2x has-text-grey",attrs:{"icon":"calendar","type":"far"}}),_c('p',[_vm._v(" Schedule ")]),_c('div',{staticClass:"is-flex is-align-items-center is-justify-content-space-between"},[_c('work-order-schedule',{attrs:{"is-overdue":_vm.isOverdue,"status":_vm.workOrder.status}})],1)],1)]),_c('column',[_c('div',{staticClass:"box is-fullheight is-round p-5"},[_c('icon',{staticClass:"fa-2x has-text-grey",attrs:{"icon":"people-arrows"}}),_c('p',[_vm._v(" Team ")]),_c('div',{staticClass:"is-flex is-align-items-center is-justify-content-space-between"},[_c('p',{staticClass:"is-size-5"},[(!_vm.$root.isPassiveUser())?_c('router-link',{attrs:{"to":{
                        name: 'team-manager',
                        params: {
                            team: _vm.workOrder.team.uuid
                        }
                    }}},[_vm._v(_vm._s(_vm.workOrder.team.name))]):_c('span',[_vm._v(_vm._s(_vm.workOrder.team.name))])],1)])],1)]),_c('column',[_c('div',{staticClass:"box is-fullheight is-round p-5"},[_c('icon',{staticClass:"fa-2x has-text-grey",attrs:{"icon":"stopwatch"}}),_c('p',[_vm._v(" Man hours spent ")]),_c('div',{staticClass:"is-flex is-align-items-center is-justify-content-space-between"},[_c('p',{staticClass:"is-size-5"},[_vm._v(_vm._s(_vm.totalHours)+" hours")])])],1)]),_c('column',[_c('div',{staticClass:"box is-fullheight is-round p-5"},[_c('icon',{staticClass:"fa-2x has-text-grey",attrs:{"icon":"bolt"}}),_c('p',[_vm._v(" Status ")]),_c('div',{staticClass:"is-flex is-align-items-center is-justify-content-space-between"},[_c('p',{staticClass:"is-size-5"},[_vm._v(_vm._s(_vm._f("ucwords")(_vm.workOrder.status)))])])],1)])],1),_c('columns',[_c('div',{staticClass:"column is-4"},[_c('p',{staticClass:"has-text-info has-text-weight-bold"},[_vm._v("Overview")]),_c('div',{staticClass:"box is-fullheight"},[_c('spaced-text',[_c('span',[_vm._v("Priority")]),_c('span',{staticClass:"has-text-weight-bold",class:{
                        'has-text-success': _vm.workOrder.priority.level === 'low',
                        'has-text-warning': _vm.workOrder.priority.level === 'medium',
                        'has-text-danger': _vm.workOrder.priority.level === 'high',
                    }},[_vm._v(_vm._s(_vm._f("ucwords")(_vm.workOrder.priority.level)))])]),_c('spaced-text',[_c('span',[_vm._v("Customer")]),_c('span',{staticClass:"has-text-weight-bold"},[_vm._v(_vm._s(_vm.workOrder.customer.name))])]),_c('spaced-text',[_c('span',[_vm._v("Location")]),_c('span',{staticClass:"has-text-weight-bold"},[_vm._v(_vm._s(_vm.workOrder.location.name))])]),_c('spaced-text',[_c('span',[_vm._v("Opened By")]),_c('span',{staticClass:"has-text-weight-bold"},[_vm._v(_vm._s(_vm.workOrder.creator.full_name))])]),_c('spaced-text',[_c('span',[_vm._v("Created")]),_c('span',{staticClass:"has-text-weight-bold"},[_vm._v(_vm._s(_vm._f("date")(_vm.workOrder.created_at,'Y-MM-DD HH:MM:ss')))])]),_c('spaced-text',[_c('span',[_vm._v("Due Date")]),(_vm.workOrder.ending_at)?_c('span',{staticClass:"has-text-weight-bold"},[_vm._v(_vm._s(_vm._f("date")(_vm.workOrder.ending_at,'Y-MM-DD HH:MM:ss')))]):_c('span',{staticClass:"has-text-weight-bold"},[_vm._v("--")])]),_c('spaced-text',[_c('span',[_vm._v("Team")]),_c('span',{staticClass:"has-text-weight-bold"},[_vm._v(_vm._s(_vm.workOrder.team.name))])]),_c('spaced-text',[_c('span',[_vm._v("Team Lead")]),_c('span',{staticClass:"has-text-weight-bold"},[_vm._v(_vm._s(_vm.workOrder.team.leader.full_name))])]),_c('spaced-text',[_c('span',[_vm._v("Checklists")]),_c('span',{staticClass:"has-text-weight-bold"},[_vm._v(_vm._s(_vm.workOrder.inspections.length))])])],1)]),_c('column',[_c('p',{staticClass:"has-text-info has-text-weight-bold"},[_vm._v("Brief")]),(_vm.workOrder.brief.length)?_c('div',{staticClass:"box is-fullheight",domProps:{"innerHTML":_vm._s(_vm.cleanedBrief)}}):_c('div',{staticClass:"box is-fullheight"},[_c('p',[_vm._v("A brief has not been provided for this work order.")])])])],1),_c('loader',_vm._b({},'loader',{ loading: _vm.loading },false),[_c('inspection-list',{staticClass:"mt-6"})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }