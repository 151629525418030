<template>
<p class="is-size-5" :class="{
    'has-text-danger': isOverdue,
    'has-text-success': !isOverdue,
}">{{ schedule }}</p>
</template>
<script>
export default {
    props: {
        isOverdue: Boolean,
        status: String
    },

    computed: {
        schedule() {
            if(this.status === 'complete' && this.overdue) {
                return 'Completed Overdue'
            }
            if(this.status === 'complete' && !this.overdue) {
                return 'Completed On Time'
            }
            if(this.status !== 'complete' && this.overdue) {
                return 'Overdue'
            }
            
            return 'On Track'
        }
    }
}
</script>