<template>
<div>
    <h5 v-if="workOrder.inspections.length" class="title is-5">Checklists <span class="has-text-weight-light">({{ workOrder.inspections.length }})</span></h5>
    <div class="index-rows">
        <div class="box is-marginless" v-for="inspection in workOrder.inspections" :key="inspection.id">
            <columns>
            <column>
                <router-link 
                    class="has-text-weight-bold is-block"
                    :to="{
                        name: 'checklist-manager',
                        params: {
                            checklist: inspection.uuid
                        }
                    }">
                    #{{ inspection.id }} 
                    <span v-if="inspection.checklist"> {{ inspection.checklist.name }}</span> 
                    <span v-else v-tooltip="'Deleted Checklist Template'">--</span> 
                    <span class="has-text-weight-light" v-if="inspection.area"> - {{ inspection.area.name }}</span>
                </router-link>
                <small class="has-text-grey">Updated {{ inspection.updated_at | asCalendar }}</small>
            </column>
            <column class="is-2 is-flex is-align-items-center is-justify-content-end">
                <icon icon="user" class="has-text-grey mr-1"/>
                <span>{{ inspection.user.full_name }}</span>
            </column>
            <column class="is-2 is-flex is-align-items-center is-justify-content-end">
                <inspectable-name 
                    :type="inspection.inspectable_type"
                    :inspectable="inspection.inspectable"
                    with-icon
                />
            </column>
            <column class="is-1 is-flex is-align-items-center is-justify-content-end">
                <checklist-images-count 
                    :answers="inspection.images_count"
                />
            </column>
            <column class="is-1 is-flex is-align-items-center is-justify-content-end">
                <circular-progress-bar
                  tooltip="Current progress for this checklist"
                  :total="inspection.answers.length"
                  :total-complete="inspection.answers.map(answer => answer.complete).length"
                  backgroundColor="#212127"
                />
            </column>
            <column class="is-1 is-flex is-align-items-center is-justify-content-end">
                <outcome-tag 
                    :status="inspection.status"
                />
            </column>
            </columns>
        </div>
    </div>

    <no-items-to-display
      :item-count="workOrder.inspections.length"
      heading="This work order has not yet received any activity"
    />
</div>  
</template>
<script>
import { mapGetters } from 'vuex'
export default {

    methods: {
        getInspectionProgress(inspection) {
            const completedCheckpoints = inspection.answers.filter(answer => answer.complete).length
            return `${completedCheckpoints}/${inspection.answers.length}`
        }
    },
    
    computed: {
        ...mapGetters('workOrder', [
            'workOrder'
        ])
    }

}
</script>